<template>
  <div class="cpt-platform-task-handle-form">
    <div class="handle-title">
      <div class="handle-inner-title">任务信息</div>
    </div>
    <el-form class="linear-form vertical edit-form" :rules="rules.form" ref="formlist"
        :model="form">
      <el-form-item label="任务名称" prop="taskTitle">
        <el-input
          disabled
          v-model="form.taskTitle"
          placeholder="请输入任务名称"
          clearable
        />
      </el-form-item>
      <el-form-item label="配备人员（个）" prop="providePersonCount">
        <el-input-number
          v-model="form.providePersonCount"
          placeholder="请输入配备人员数量"
          clearable
          :min="1"
        />
      </el-form-item>
      <el-form-item label="任务类型" prop="taskCateId">
        <el-select
          disabled
          v-model="form.taskCateId"
          placeholder="请选择任务类型"
          clearable
        >
          <el-option
            v-for="item in drd.task_type"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="场景类型" prop="taskTypeId">
        <el-select
          v-model="form.taskTypeId"
          disabled
          placeholder="请选择场景类型"
          clearable
        >
          <el-option
            v-for="item in drd.task_cate"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <div class="linear-fields">
        <el-form-item label="配备无人机（架）" prop="deviceType" ref="Typedevice">
          <div class="overflow-auto">
          <div
            v-for="(item, index) in form.deviceType"
            :key="item.key"
            style="display: flex; align-items: center; margin-bottom: 6px"
          >
            <el-select
              v-model="item.deviceTypeId"
              placeholder="无人机类型"
              clearable
              ref="selectChange1"
            >
              <el-option
                v-for="item in drd.uav_type"
                :key="item.id"
                :label="item.title"
                :value="item.id"
                :disabled="item.disabled"
              />
            </el-select>
            <el-input-number
              v-model="item.count"
              placeholder="无人机数量"
              clearable
              :min="1"
            />
            <span
              v-if="index === 0"
              @click="
               form.deviceType.length < drd.uav_type.length? form.deviceType.push({
                  deviceTypeId: null,
                  count: null,
                  key: nanoid(),
                }):$message({message: `最多只能选择${drd.uav_type.length}种无人机类型`,type: 'warning'})
              "
              class="fields-icon el-icon-plus"
            ></span>
            <span
              v-else
              @click="onetwo(index)"
              class="fields-icon icon-delete el-icon-minus"
            ></span>
          </div>
          </div>
        </el-form-item>
      </div>
      <div class="linear-fields">
        <el-form-item label="配备挂载（个）" prop="mountType">
          <div class="overflow-auto">
          <div
            v-for="(item, index) in form.mountType"
            :key="item.key"
            style="display: flex; align-items: center; margin-bottom: 6px"
          >
            <el-select
              v-model="item.mountTypeId"
              placeholder="挂载类型"
              clearable
              ref="selectChange2"
            >
              <el-option
                v-for="item in drd.mount_type"
                :key="item.id"
                :label="item.title"
                :value="item.id"
                :disabled="item.disabled"
              />
            </el-select>
            <el-input-number
              v-model="item.count"
              placeholder="挂载数量"
              clearable
              :min="1"
            />
            <span
              v-if="index === 0"
              @click="
               form.mountType.length < drd.mount_type.length ? form.mountType.push({
                  mountTypeId: null,
                  count: null,
                  key: nanoid(),
                }):$message({message: `最多只能选择${drd.mount_type.length}种挂载类型`,type: 'warning'})
              "
              class="fields-icon el-icon-plus"
            ></span>
            <span
              v-else
              @click="form.mountType.splice(index, 1)"
              class="fields-icon icon-delete el-icon-minus"
            ></span>
          </div>
          </div>
        </el-form-item>
      </div>
      <!-- <div class="linear-fields">
        <el-form-item label="配备车辆" prop="mountType">
          <div class="overflow-auto">
          <div
            v-for="(item, index) in form.vehicleArray"
            :key="item.key"
            style="display: flex; align-items: center; margin-bottom: 6px"
          >
            <el-cascader
              class="input-orgname"
              placeholder="请选择需求单位"
              v-model="item.id"
              :options="vehicleArrayOptions"
              :show-all-levels="false"
              :props="{
                children: 'child',
                label: 'name',
                value: 'id',
              }"
              clearable
              @change="selectChange(edit_form.dutyOrganId, selectList)"
            >
            </el-cascader>
            <span
              v-if="index === 0"
              @click="
               form.mountType.length < drd.mount_type.length ? form.mountType.push({
                  mountTypeId: null,
                  count: null,
                  key: nanoid(),
                }):$message({message: `最多只能选择${drd.mount_type.length}种挂载类型`,type: 'warning'})
              "
              class="fields-icon el-icon-plus"
            ></span>
            <span
              v-else
              @click="form.mountType.splice(index, 1)"
              class="fields-icon icon-delete el-icon-minus"
            ></span>
          </div>
          </div>
        </el-form-item>
      </div> -->
      <el-form-item label="飞行时间" prop="date">
        <el-date-picker
          disabled
          v-model="form.date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="配备车辆（辆）" prop="provideCarCount">
        <el-input-number
          v-model="form.provideCarCount"
          placeholder="请输入配备车辆数量"
          clearable
          :min="0"
        />
      </el-form-item>
      <div class="linear-fields" style="width:100%">
        <el-form-item label="配备车辆" prop="vehicleArray">
          <div class="overflow-auto">
            <el-cascader
              ref="elCascader"
              style="width:100%"
              class="input-orgname"
              @change="logArr"
              placeholder="请选择要分配的车辆"
              v-model="form.vehicleArray"
              :options="vehicleArrayOptions"
              :show-all-levels="true"
              :props="{
                children: 'vehicles',
                label: 'carNumber',
                value: 'id',
                multiple: true,
              }"
              clearable
            >
            </el-cascader>
          </div>
        </el-form-item>
      </div>
      <div class="linear-fields workData">
        <el-form-item
          label="请选择详细作业时间:"
          ref="dateWork"
          prop="workDate"
        >
          <div class="overflow-auto">
            <div
              v-for="(item, index) in form.workDate"
              :key="item.key"
              class="linear-fields"
              style="display: flex; align-items: center; margin-bottom: 6px"
            >
              <el-form-item prop="start">
                <el-date-picker
                  ref="datePicker1"
                  v-model="form.workDate[index].start"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="detailsPickerOptionsStart"
                  type="datetime"
                  placeholder="开始日期"
                  @focus="detailsDateStartPickerFocus(index)"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
              <span style="width: 60px; color: #009aff; text-align: center;margin-bottom: 20px;padding: 0 10px;"
                >—</span
              >
              <el-form-item prop="end">
                <el-date-picker
                  ref="datePicker2"
                  v-model="form.workDate[index].end"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="detailsPickerOptionsEnd"
                  type="datetime"
                  placeholder="结束日期"
                  @focus="detailsDateEndPickerFocus(index)"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
              <span
                v-if="index === 0"
                @click="
                  form.workDate.length < 10
                    ? form.workDate.push({ start: null, end: null, key:nanoid() })
                    : $message({
                        message: '最多只能选择十个详细作业时间',
                        type: 'warning',
                      })
                "
                class="fields-icon el-icon-plus add-and-red"
              ></span>
              <span
                v-else
                @click="
                  form.workDate.splice(index, 1);
                  form.workDate.some((v) => v.length == 0)
                    ? ''
                    : $refs.dateWork.clearValidate();
                "
                class="fields-icon icon-delete el-icon-minus add-and-red"
              ></span>
            </div>
          </div>
        </el-form-item>
      </div>

      <el-form-item label="任务地点">
        <div style="display: flex; align-items: center">
          <el-form-item style="margin-bottom: 0px; flex: 1" prop="origin">
            <el-input
              v-model="form.origin"
              disabled
              placeholder="开始位置"
              clearable
            ></el-input>
          </el-form-item>
          <span style="width: 50px; color: #009aff; text-align: center">—</span>
          <el-form-item style="margin-bottom: 0px; flex: 1" prop="destination">
            <el-input
              v-model="form.destination"
              disabled
              placeholder="结束位置"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 区域查看 -->
          <span @click="openAreaPopup" class="iconfont icon-luxianyulan area-button" title="区域查看"></span>
        </div>
      </el-form-item>
      <el-form-item label="作业天数（天）" prop="workDayCount">
        <el-input-number
          v-model="form.workDayCount"
          placeholder="请输入作业天数"
          clearable
          :min="1"
        />
      </el-form-item>
      <el-form-item label="需求单位" prop="dutyOrganName">
        <el-input
          v-model="form.organizationName"
          disabled
          placeholder="请输入需求单位"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="对接人" prop="userName">
        <el-input
          v-model="form.userName"
          disabled
          placeholder="请输入对接人"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="form.phone"
          disabled
          placeholder="请输入联系电话"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item label="电池编号" prop="batteryNumbers">
        <el-select ref="selectChange3"  v-model="form.batteryNumbers" placeholder="请选择电池编号" multiple  clearable class="Battery" :multiple-limit="2">
          <el-option
                v-for="item in drd.BatteryList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="需求描述" style="width: 100%" prop="remark">
        <el-input
          v-model="form.remark"
          disabled
          placeholder="请输入需求描述"
          type="textarea"
          clearable
        ></el-input>
      </el-form-item>
      
      <el-form-item label="分配航线" prop="flightLineId">
          <div class="flightLine">
          <el-select ref="selectChange4"  v-model="form.flightLineId" placeholder="请选择航线" clearable>
            <el-option
                v-for="item in drd.flightLine"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
        </el-select>
        <span
              @click="openResources"
              class="iconfont icon-chulixuqiu1"
            ></span>
            </div>
      </el-form-item>
    </el-form>
    
    <!-- 区域选择弹框 -->
    <div v-if="areaPopupIsShow" class="area-select-popup">
      <div class="content">
        <div class="button">
          <el-button @click="closeArea" type="primary">关闭</el-button>
        </div>
        <Drawky
          :airspcelist="areaPointList"
          :readonly="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import API from "@/api";
import Drawky from "@/components/drawky"; // 绘制区域的
export default {
  props:{
    openResources:{
      type: Function,
      default: () => () => {},
    },
    id:{
      type: String | Number,
      default: () =>  null,
    }
  },
  components: {
    Drawky
  },
  data() {
    let start = (rule, value, callback) => {
      this.workDataRules();
      callback();
    };
    let end = (rule, value, callback) => {
      this.workDataRules();
      callback();
    };
    let editTime = (rule, value, callback) => {
      value.forEach((v) => {
        if (v !== null) {
          if (!v.start || !v.end) {
            callback(new Error("请选择详细作业时间"));
          }
        }
      });
      callback();
    };
    let editDevtype = (rule, value, callback) => {
      value.forEach((v)=>{
        if (v.deviceTypeId==null || v.deviceTypeId=='') {
            callback(new Error('请选择无人机类型'));
        }
        if (!v.count) {
          callback(new Error('无人机数量不能为0'));
        }
      })
        callback()
      };
    let editMountType = (rule, value, callback) => {
      value.forEach((v)=>{
        if (v.mountTypeId==null || v.mountTypeId=='') {
            callback(new Error('请选择挂载类型'));
        }
        if (!v.count) {
          callback(new Error('无人机数量不能为0'));
        }
      })
        callback()
      };
    return {
      vehicleArrayOptions: [], // 车辆选择下拉列表
      areaPopupIsShow: false, // 控制区域弹框展示隐藏
      areaPointList: null, // 区域点保存
      flightUser: null, // 存储已分配人员信息
      detailsPickerOptionsStart: {}, //详细作业开始时间限制
      detailsPickerOptionsEnd: {}, //详细作业结束时间限制
      form: {
        taskTitle: null,
        deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }],
        mountType: [{ mountTypeId: null, count: null, key: nanoid() }],
        vehicleArray: [],
        providePersonCount: null,
        taskTypeId: null,
        taskCateId: null,
        date: null,
        date1: null,
        date2: null,
        provideCarCount: null,
        origin: null,
        destination: null,
        workDayCount: null,
        flightLineId: null,
        // dutyOrganName: null,
        phone: null,
        remark: null,
        batteryNumbers: [],
        workDate: [{start:null,end:null,key:1}],
        userName: null,
        organizationName:null,
      },
      cahgeDay1: 0, // 看预计开始时间是不是第一次变化
      cahgeDay2: 0, // 看预计结束时间是不是第一次变化
      rules: {
        form: {
          taskTitle: [
            { required: true, message: "请输入需求名称", trigger: "blur" },
          ],
          providePersonCount: [
            { required: true, message: "请输入配备人员", trigger: "blur" },
          ],
          taskCateId: [
            { required: true, message: "请选择任务类型", trigger: "blur" },
          ],
          taskTypeId: [
            { required: true, message: "请选择场景类型", trigger: "blur" },
          ],
          date: [
            { required: true, message: "请选择飞行时间", trigger: "blur" },
          ],
          provideCarCount: [
            { required: true, message: "请输入配备车辆", trigger: "blur" },
          ],
          workDayCount: [
            { required: true, message: "请输入作业天数", trigger: "blur" },
          ],
          // flightLineId: [
          //   { required: true, message: "请选择航线", trigger: "change" },
          // ],
          batteryNumbers: [
            { required: true, message: "请选择电池编号", trigger: ["blur",'change']},
          ],
          remark: [
            { required: true, message: "请输入需求描述", trigger: "blur" },
          ],
          deviceType: [
            { required: true, validator: editDevtype, trigger: "change" },
          ],
          mountType: [
            { required: true, validator: editMountType, trigger: "change" },
          ],
          start: [{ required: true, validator: start, trigger: "blur" }],
          end: [{ required: true, validator: end, trigger: "blur" }],
          workDate: [
            { required: true, validator: editTime, trigger: "blur" },
          ],
        },
      },
      drd: {
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        flightLine: [],
        BatteryList: []
      },
    };
  },
  methods: {
    logArr(){
      console.log(this.form.vehicleArray);
    },
    workDataRules() {
      //判断详细时间数据内的开始时间与结束时间是否存在
      //存在的话就清除表单验证
      let workDateBoolean = this.form.workDate.every(
        (v) => v.start && v.end
      );
      if (workDateBoolean) this.$refs.dateWork.clearValidate();
    },
    // -------------------------------------------------------------------------------时间选择限制开始
    detailsDateStartPickerFocus() {
      // 详细作业开始时间获取焦点做选择限制
      this.detailsPickerOptionsStart = {
        disabledDate: (time) => {
          // 日期选择限制
          return (
            time.getTime() <
              new Date(this.form.date1).getTime() - 8.64e7 + 1 ||
              time.getTime() > new Date(this.form.date2).getTime()
          );
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    detailsDateEndPickerFocus(index) {
      // 详细作业结束时间获取焦点做选择限制
      this.detailsPickerOptionsEnd = {
        disabledDate: (time) => {
          // 日期选择限制
          let day1 = this.form.date1;
          if (this.form.workDate[index].start) {
            day1 = this.form.workDate[index].start;
          } // -8.64e7是为了能选同一天， +1 是为了他选了那天的时间00:00:00不能选前一天的
          return (
            time.getTime() < new Date(day1).getTime() - 8.64e7 + 1 ||
            time.getTime() > new Date(this.form.date2).getTime()
          );
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    getNowTime(val) {
      // 时间时分秒限制
      let date = new Date(val);
      let hours = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hours < 10) hours = "0" + hours;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      let timeStr = `${hours}:${minute}:${second}`;
      return timeStr;
    },
    //--------------------------------------------------------------------------------时间选择限制结束
    onetwo(index){
      this.form.deviceType.splice(index, 1)
      this.form.deviceType.forEach((v)=>{
        
        if (v.deviceTypeId==null || v.deviceTypeId=='' || !v.count) {
            
        }else{
          this.$refs.Typedevice.clearValidate()
        }
      })
    },
    nanoid() {
      return nanoid();
    },
    openAreaPopup() {  // 查看区域弹框
        if(this.areaPointList){
          this.areaPopupIsShow = true
        } else {
          this.$message.warning('未绘制区域')
        }
    },
    closeArea() {  // 关闭区域弹框
        this.areaPopupIsShow = false
    },
    init_form(data) {  // 把处理需求请求回来的数据放到form
      Object.keys(this.form).forEach((key) => {
        if (key === "deviceType" && (!data[key] || !data[key].length)) {
          this.form[key] = [{ deviceTypeId: null, count: null, key: nanoid() }];
        } else if (key === "mountType" && (!data[key] || !data[key].length)) {
          this.form[key] = [{ mountTypeId: null, count: null, key: nanoid() }];
        } else if (key === "date") {
          this.form[key] = [];
          if (data["startedAt"]) {
            this.form[key].push(new Date(data["startedAt"]));
          }
          if (data["endAt"]) {
            this.form[key].push(new Date(data["endAt"]));
          }
        } else {
          this.form[key] = data[key] || null;
        }
      });
      if(data.locationArea){
        this.areaPointList = {regionList:null}
        let araeArr = []
        araeArr = data.locationArea.split(' ')
        let lastAraeArr = []
        araeArr.forEach(item=>{
          let areaPoint = item.split(',')
          lastAraeArr.push(areaPoint[0]*1)
          lastAraeArr.push(areaPoint[1]*1)
        })
        this.areaPointList.regionList = lastAraeArr
      }
    },
    async drd_task_type() {
      try {
        let res = await API.TASK.GetDrdType();
        res = res.data
        this.drd.task_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_task_cate() {
      try {
        let res = await API.TASK.GetDrdCate();
        res = res.data
        this.drd.task_cate = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_uav_type() {
      try {
        let res = await API.UAV.GetDrdType();
        res = res.data
        this.drd.uav_type = res.map((item) => ({
          ...item,
          title: item.name,
          disabled: false
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_mount_type() {
      try {
        let res = await API.MOUNT.GetDrdType();
        res = res.data
        
        this.drd.mount_type = res.map((item) => ({
          ...item,
          title: item.name,
          disabled: false
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async async_get() {  // 获取需求数据
    //当为日历内的组件时将穿入的id作为请求的id
    let id
    if (this.id) {id=this.id}else{id = this.$route.params.id;}
      const { data } = await API.TASK.Get(id);
      this.init_form(data);
      this.flightUser = data.flightUser
      let date002 = []
      if( data.gzTime && data.gzTime.length > 0) {
        data.gzTime.map( (item) => date002.push({ start: item.start, end: item.end, key: this.nanoid() }) )
      } else {
        date002 = [{ start: null, end: null, key: this.nanoid() }]
      }
      this.form.workDate = date002
      this.form.date1 = data.startedAt;
      this.form.date2 = data.endAt;
      if(data.vehicleId){
        let arr = JSON.parse(data.vehicleId)
				let lstArr = []
				arr.map(item=>{
					lstArr.push([item.carTypeId,item.carId])
				})
        this.form.vehicleArray = lstArr
      } else {
        this.form.vehicleArray = []
      }
    },
    async FlightLine() {  // 获取航线下拉数据
      const { data } = await API.TASK.Drd()
      this.drd.flightLine = data
    },
    async VehicleList() {  // 车辆列表获取
      try {
        let res = await API.TASK.VehicleList();
        this.vehicleArrayOptions = res.data
        this.vehicleArrayOptions.map(item=>{
          item.carNumber = item.carTypeName
          if(!item.vehicles){
            item.disabled = true
          }
        })
      } catch (e) {
        console.error(e);
      }
    },
    async getBatteryList() {
      const { data } = await API.TASK.BatteryList()
      this.drd.BatteryList = data
    }
  },
  watch: {
    $route(){
      this.async_get();
      this.drd_task_type();
      this.drd_task_cate();
      this.drd_uav_type();
      this.drd_mount_type();
      this.FlightLine()
      this.getBatteryList()
    },
    'form.mountType': {   // 配备挂载下拉选择限制
      handler: function(val){
        let indexarr = []
        val.forEach(item=>{
          if(item.mountTypeId) {
            indexarr.push(item.mountTypeId)
          }
        })
        
        this.drd.mount_type.forEach(itemIn=>{
            itemIn.disabled = false
        })

        indexarr.forEach(item=>{
          this.drd.mount_type.forEach(itemIn=>{
            if(itemIn.id == item) {
              itemIn.disabled = true
            }
              
          })
        })
      },
      deep:true,
      immediate:true
    },
    "form.deviceType": {  // 配备无人机下拉限制
      handler: function(val){
        let indexarr = []
        val.forEach(item=>{
          if(item.deviceTypeId) {
            indexarr.push(item.deviceTypeId)
          }
        })
        this.drd.uav_type.forEach(itemIn=>{
            itemIn.disabled = false
        })

        indexarr.forEach(item=>{
          this.drd.uav_type.forEach(itemIn=>{
            if(itemIn.id == item) {
              itemIn.disabled = true
            }
              
          })
        })
      },
      deep:true,
      immediate:true
    },
    workDateCopyc: { // 监视详细作业时间变化
      deep: true,
      handler: function (newVal, oldVal) {
        let starIndex = -1;
        let endIndex = -1;
        if (newVal.length == oldVal.length) {
          newVal.forEach((item, index) => {
            if (item.start != oldVal[index].start) {
              starIndex = index;
            }
            if (item.end != oldVal[index].end) {
              endIndex = index;
            }
          });
        }
        if (starIndex != -1) {
          if (newVal[starIndex].start) {
            const date = new Date(
              new Date(newVal[starIndex].start).setHours(0, 0, 0, 0)
            ).getTime();
            const today1 = new Date(
              new Date(this.form.date1).setHours(0, 0, 0, 0)
            ).getTime();
            const today2 = new Date(
              new Date(this.form.date2).setHours(0, 0, 0, 0)
            ).getTime();
            if (date != today1 && date != today2) {
              // 如果选的日期不是和当天同一天
              this.detailsPickerOptionsStart.selectableRange =
                "00:00:00 - 23:59:59";
            } else if(date == today1 && date == today2 ){ // 如果是开始和结束时间是同一天
                this.detailsPickerOptionsStart.selectableRange = `${this.getNowTime(this.form.date1)} - ${this.getNowTime(this.form.date2)}`;
            }  else if (date == today1) {
              // 如果是同一天
              this.detailsPickerOptionsStart.selectableRange = `${this.getNowTime(
                this.form.date1
              )} - 23:59:59`;
            } else if (date == today2) {
              this.detailsPickerOptionsStart.selectableRange = `00:00:00 - ${this.getNowTime(
                this.form.date2
              )}`;
            }
            if (
              new Date(newVal[starIndex].start).getHours() <
                new Date(this.form.date1).getHours() &&
              new Date(newVal[starIndex].start).getDay() ==
                new Date(this.form.date1).getDay() &&
              new Date(newVal[starIndex].start).getDate() ==
                new Date(this.form.date1).getDate()
            ) {
              this.form.workDate[starIndex].start = new Date(
                this.form.date1
              );
            }
            if (
              new Date(newVal[starIndex].start).getHours() >
                new Date(this.form.date2).getHours() &&
              new Date(newVal[starIndex].start).getDay() ==
                new Date(this.form.date2).getDay() &&
              new Date(newVal[starIndex].start).getDate() ==
                new Date(this.form.date2).getDate()
            ) {
              this.form.workDate[starIndex].start = new Date(
                this.form.date2
              );
            }
          }
          if (
            this.form.workDate[starIndex].end &&
            newVal[starIndex].start &&
            new Date(newVal[starIndex].start).getTime() >
              new Date(this.form.workDate[starIndex].end).getTime()
          ) {
            this.form.workDate[starIndex].end = null; // 如果大于结束时间，清空结束时间
          }
        }
        if (endIndex != -1) {
          if (newVal[endIndex].end) {
            const date = new Date(
              new Date(newVal[endIndex].end).setHours(0, 0, 0, 0)
            ).getTime();
            let day1 = this.form.date1;
            if (this.form.workDate[endIndex].start) {
              day1 = this.form.workDate[endIndex].start;
            }
            const today1 = new Date(
              new Date(day1).setHours(0, 0, 0, 0)
            ).getTime();
            const today2 = new Date(
              new Date(this.form.date2).setHours(0, 0, 0, 0)
            ).getTime();
            if (date != today1 && date != today2) {
              // 如果选的日期不是和当天同一天
              this.detailsPickerOptionsEnd.selectableRange =
                "00:00:00 - 23:59:59";
            } else if(date == today1 && date == today2 ){ // 如果是开始和结束时间是同一天
                this.detailsPickerOptionsEnd.selectableRange = `${this.getNowTime(
                day1
              )} - ${this.getNowTime(
                this.form.date2
              )}`;
            } else if (date == today1) {
              // 如果和开始时间同一天
              this.detailsPickerOptionsEnd.selectableRange = `${this.getNowTime(
                day1
              )} - 23:59:59`;
            } else if (date == today2) { // 如果和结束时间同一天
              this.detailsPickerOptionsEnd.selectableRange = `00:00:00 - ${this.getNowTime(
                this.form.date2
              )}`;
            }
            if (
              new Date(newVal[endIndex].end).getHours() <
                new Date(day1).getHours() &&
              new Date(newVal[endIndex].end).getDay() ==
                new Date(day1).getDay() &&
              new Date(newVal[endIndex].end).getDate() ==
                new Date(day1).getDate()
            ) {
              this.form.workDate[endIndex].end = new Date(day1);
            }
            if (
              new Date(newVal[endIndex].end).getHours() >
                new Date(this.form.date2).getHours() &&
              new Date(newVal[endIndex].end).getDay() ==
                new Date(this.form.date2).getDay() &&
              new Date(newVal[endIndex].end).getDate() ==
                new Date(this.form.date2).getDate()
            ) {
              this.form.workDate[endIndex].end = new Date(
                this.form.date2
              );
            }
          }
        }
      },
    },
  },
  computed: {
    workDateCopyc() {// 深度监视详细作业时间，要先复制一份，不然拿不到旧值
      return JSON.parse(JSON.stringify(this.form.workDate));
    },
  },
  mounted() {
    this.async_get();
    this.drd_task_type();
    this.drd_task_cate();
    this.drd_uav_type();
    this.drd_mount_type();
    this.FlightLine()
    this.getBatteryList()
    this.VehicleList()
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  
    .area-select-popup{
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(12, 34, 67, 0.5);
      z-index: 7000;
      .content{
        position: absolute;
        width: 1600px;
        height: 800px;
        left: 50%;
        top: 100px;
        margin-left: -800px;
        border: 1px solid #009aff;
        .button{
          position: absolute;
          top: 20px;
          right: 20px;
          width: 80px;
          z-index: 1000;
          .el-button--primary{
            background-color: #69b3e5;
          }
        }
      }
    }
  .edit-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .area-button{
      width: 40px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #009aff;
      margin-left: 5px;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
    }
    .el-form-item {
      width: calc(50% - 6px);
    }
    .linear-fields {
      width: calc(50% - 6px);
      margin-bottom: 20px;
      border: none;
      max-height: none;
      padding: 0;
      .el-form-item {
        width: 100%;
      }
      .overflow-auto{
          overflow: auto;
          padding: 30px;
          max-height: 40px;
          border: 1px solid #27526e;
          .add-and-red{
            margin-bottom: 18px;
          }
      }
    }
    .workData {
      width: 100%;
      .fields-icon{
        width: 40px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #009aff;
        margin-left: 5px;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        flex-shrink: 0;
      }
      .fields-icon.icon-delete{
            border: 1px solid tomato;
            color: tomato;
      }
      .overflow-auto{
        .linear-fields{
          width: 100%;
        }
      }
    }
  }
}

.cpt-platform-task-handle-form {
  overflow: auto;
}

.flightLine {
  display: flex;
  .el-select {
    width: 91%;
  }
  span{
  width: 40px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #009aff;
    margin-left: 5px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    flex-shrink: 0;
}
}
.iconfont {
        cursor: pointer;
        font-size: 18px;
        color: #9fc4ff;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
      }
</style>
<style lang="scss">
.cpt-platform-task-handle-form .Battery .el-tag {
  background-color: #009aff;
  border: none;
  color: #9fc4ff;
} 
.el-cascader__tags .el-tag  {
   background-color: #009aff;
   border: none;
  color: #9fc4ff;
}
</style>
