<template>
  <div class="cpt-platform-task-handle-pilot">
    <!-- 已选择运维人员展示 -->
    <div
      class="select-man"
      v-if="fromRef && fromRef.flightUser && fromRef.flightUser.length > 0"
    >
      <el-collapse v-model="activeName">
        <el-collapse-item title="之前已分配运维人员：" name="1">
          <div
            class="select-man-item"
            v-for="(item, index) in fromRef.flightUser"
            :key="index"
          >
            <div class="item">姓名：{{ item.name }}</div>
            <div class="item">联系方式：{{ item.phone }}</div>
            <div class="item">所属单位：{{ item.ORG_NAME }}</div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <Table
      :columns="columns"
      :dataSource="dataSource"
      style="max-height: 400px"
    >
      <template #free="{ row }">
        <div class="action">
          <span title="是否正在作业" class="iconfont">{{
            row.free === 1 ? "空闲" : "非空闲"
          }}</span>
        </div>
      </template>
      <template #status="{ row }">
        <div class="action">
          <span title="分配情况" class="iconfont">{{
            statusList[row.status]
          }}</span>
        </div>
      </template>
      <template #action="{ row }">
        <el-checkbox
          v-model="dataSource[row.index].checked"
          @change="rows(row.id)"
        ></el-checkbox>
      </template>
    </Table>
    <div class="save">
      <el-button type="primary" @click="change()">保存</el-button>
    </div>
  </div>
</template>

<script>
import Table from "@/components/common/table";
import API from "@/api";
import dayjs from "dayjs";

export default {
  props: {
    fromRef: {
      type: Object,
      default: () => {},
    },
  },
  components: { Table },
  data() {
    return {
      activeName: "1",
      selsectId: [],
      radioId: 0,
      columns: [
        { prop: "name", label: "运维人员" },
        { prop: "phone", label: "联系手机号" },
        { prop: "free", slot: true, label: "是否正在作业" },
        { prop: "status", slot: true, label: "分配情况" },
        { prop: "organizationName", label: "所属单位" },
        {
          prop: "action",
          slot: true,
          label: "操作",
          width: "200px",
        },
      ],
      dataSource: [],
      statusList: {
        0: "未分配",
        1: "已分配",
        2: "其他任务分配",
      },
    };
  },
  methods: {
    rows(id) {
      // 存储选择的运维
      if (this.selsectId.indexOf(id) > -1) {
        this.selsectId = [];
      } else {
        this.selsectId = [id];
      }
    },
    async operatorList() {
      // 获取列表
      const id = this.$route.params.id;
      const { data } = await API.TASK.flyingUser({ taskId: id });
      data.map((item, index) => {
        item.checked = false;
        item.index = index;
        if (this.selsectId.length > 0 && this.selsectId[0] == item.id) {
          item.checked = true;
        }
      });
      this.dataSource = data;
    },
    async change() {
      // 保存处理需求
      if (this.selsectId.length <= 0) {
        this.$message.warning("未分配运维人员");
        return;
      }
      this.fromRef.$refs["formlist"].validate(async (valid,object) => {
        if (valid) {
          const id = this.$route.params.id;
          const { date, workDate, vehicleArray, ...rest } = this.fromRef.form;
          let gzTime = [];
          if (workDate[0].length === 0) {
          } else {
            workDate.map((item) =>
              gzTime.push({
                start: dayjs(item.start).format("YYYY-MM-DD HH:mm:ss"),
                end: dayjs(item.end).format("YYYY-MM-DD HH:mm:ss"),
              })
            );
          }
          let vehicleId = [];
          if (vehicleArray.length > 0) {
            vehicleArray.map((item) => {
              vehicleId.push(item[1] + "");
            });
          }
          vehicleId = "[" + vehicleId.toString() + "]";
          if (vehicleId == "[]") {
            vehicleId = null;
          }
          const params = {
            ...rest,
            startedAt: dayjs(date[0]).format("YYYY-MM-DD HH:mm:ss"),
            endAt: dayjs(date[1]).format("YYYY-MM-DD HH:mm:ss"),
            deviceType:
              rest.deviceType.length >= 1 && rest.deviceType[0].deviceTypeId
                ? rest.deviceType
                : [],
            mountType:
              rest.mountType.length >= 1 && rest.mountType[0].mountTypeId
                ? rest.mountType
                : [],
            gzTime,
            id,
            operatorIds: this.selsectId, // 运维人员
            vehicleId,
            processingStatus: 1,
          };

          const res = await API.TASK.handle(params);
          if (res.status == 1) {
            // 清除WebSocket的已经处理的这条信息
            // let aavv = await API.TASK.removeMsg({
            //   id: this.$route.params.id,
            // });
            this.$el_message("处理成功", () => {
              this.$router.push("/platform/order");
            });
          } else {
            this.$el_message(res.message);
          }
        } else {
          setTimeout(() => {  // 如果不通过验证，跳到没有通过验证的输入框并获取焦点
            let isError = document.getElementsByClassName("is-error");
            if (isError.length > 0) {
              isError[0].querySelector('input').focus()
            }
          }, 100)
        }
      });
    },
  },
  watch: {
    $route() {
      this.operatorList();
    },
    "fromRef.flightUser"(val) {
      if (val && val.length > 0) {
        this.selsectId = val.map((item) => {
          return item.id;
        });
      }
    },
    selsectId(val) {
      if (val.length > 0) {
        this.dataSource.map((item) => {
          if (item.id == val[0]) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      }
    },
  },
  mounted() {
    this.operatorList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: 600;
    color: #9fc4ff;
  }
  .el-collapse-item__content {
    color: #9fc4ff;
  }
  .el-collapse-item__content {
    padding-bottom: 16px;
  }
  .el-collapse-item__header {
    background: rgba(82, 39, 39, 0);
  }
  .el-collapse-item__wrap {
    background: rgba(82, 39, 39, 0);
    border: 0px;
  }
  .el-collapse-item__header {
    border: 0px;
  }
  .el-collapse {
    border: 0px;
  }
}
.cpt-platform-task-handle-pilot {
  margin-top: 16px;
  .action {
    .iconfont {
      font-size: 12px;
    }
  }
  .select-man {
    margin-bottom: 16px;
    color: #9fc4ff;
    font-size: 16px;
    .select-man-item {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70%;
      .item {
        width: 33%;
      }
    }
  }
  .save {
    float: right;
    margin-top: 6px;
  }
}
.icon-chulixuqiu1 {
  cursor: pointer;
}
</style>
