<template>
  <div class="page-platform-task-handle">
    <Box>
      <div class="page-platform-task-handle-inner" @scroll="scrollYRules">
        <div @click="back" class="back-button" ></div>
        <Form :openResources='openResources' ref="fromRef" :id="id"/>
        <!-- 资源调度 -->
        <Resources v-if="resourcesIsShow" :closeResources="closeResources" :airLineCreate='airLineCreate'/>
        <Pilot :fromRef="fromRef" />
      </div>
    </Box>
  </div>
</template>

<script>
import Box from "@/components/platform/common/box";
import API from "@/api";
import Form from "./form";
import Resources from "./resources";
import Pilot from "./pilot";
export default {
  props:{
    id:{
      type: String | Number,
      default: () =>  null,
    }
  },
  components: { Box, Form, Pilot, Resources },
  data() {
    return {
      resourcesIsShow:false, // 控制资源调度块是否打开
      fromRef: null
    }
  },
  methods: {
    closeResources() { //控制资源调度块关闭函数
        this.resourcesIsShow = false
    },
    openResources() { //控制资源调度块打开函数
        this.resourcesIsShow = true
    }, 
    airLineCreate(id) { //航线创建成功的函数
        this.fromRef.FlightLine()   
        this.fromRef.form.flightLineId = id*1
    },
    back() {
      if (this.id) {
        this.$emit('onCloseBox')
      }else{
        this.$router.go(-1)
      }
    },
    scrollYRules(){
        document.querySelectorAll('.el-select-dropdown').forEach((v,i)=>{
          if (v.style.display!='none') {
            this.$refs.fromRef.$refs.selectChange1[0].blur()
            this.$refs.fromRef.$refs.selectChange2[0].blur()
            this.$refs.fromRef.$refs.selectChange3.blur()
            this.$refs.fromRef.$refs.selectChange4.blur()
          }
        })
        document.querySelectorAll('.el-picker-panel').forEach((v,i)=>{
          if (v.style.display!='none') {
            this.$refs.fromRef.$refs.datePicker1[0].hidePicker()
            this.$refs.fromRef.$refs.datePicker2[0].hidePicker()
          }
        })
        document.querySelectorAll('.el-cascader__dropdown').forEach((v,i)=>{
          if (v.style.display!='none') {
            this.$refs.fromRef.$refs.elCascader.dropDownVisible = false
          }
        })
    }
  },
  watch: {
    fromRef(val){
      if(val){
      }
    },
  },
  mounted() {
    this.fromRef = this.$refs.fromRef
  }
};
</script>

<style lang="scss">
.handle-title {
  width: 392px;
  margin: 0 auto 24px;
  flex-shrink: 0;
  background-image: linear-gradient(
    270deg,
    rgba(105, 180, 255, 0) 0%,
    rgba(105, 180, 255, 0.3) 47%,
    rgba(105, 180, 255, 0) 100%
  );
  box-sizing: border-box;
  padding: 4px 0;
  position: relative;
  height: 32px;
  &:first-child {
    margin-top: 0px;
  }
  &::before {
    content: " ";
    display: block;
    width: 5px;
    height: 2px;
    background: #4dabff;
    position: absolute;
    left: 0;
    top: 0;
  }
  &::after {
    content: " ";
    display: block;
    width: 5px;
    height: 2px;
    background: #4dabff;
    position: absolute;
    right: 0;
    top: 0;
  }
  .handle-inner-title {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      270deg,
      rgba(5, 130, 255, 0) 0%,
      rgba(5, 130, 255, 0.5) 47%,
      rgba(5, 130, 255, 0) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    &::before {
      content: " ";
      display: block;
      width: 5px;
      height: 2px;
      background: #4dabff;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    &::after {
      content: " ";
      display: block;
      width: 5px;
      height: 2px;
      background: #4dabff;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.page-platform-task-handle {
  box-sizing: border-box;
  height: 100%;
  width: 1286px;
  margin: 0 auto;
  overflow: hidden;
  .page-platform-task-handle-inner {
    overflow: auto;
    padding: 32px 96px;
  }
}
.back-button{
  width: 17px;
  height: 17px;
  position: absolute;
  top: 20px;
  right: 32px;
  cursor: pointer;
  background-image: url(~@/assets/platform/message/task/details/closure.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>